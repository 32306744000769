/*
 * Servizio che si occupa della gestione dei tutor
*/

module TutorService {
    let app = angular.module("app");

    app.factory("TutorService", ["$resource", "GlobalApplicationData", ($resource: ng.resource.IResourceService, GlobalApplicationData: any) => {
        let serv = {    
            
            // Cancella il tutor selezionato
            deleteTutor: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/delete-supplier-person', {
                TutorId: "@TutorId"
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            // Recupera le informazioni di un supplier person con possibilità di caricare i supplier (le mostra nell'edit)
            getSupplierPersonDetail: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-supplier-person-by-id/:supplierPersonId', {
                supplierPersonId: "@supplierPersonId",
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            // Recupera i nomi dei supplier (per autocomplete)
            listSupplierByLike: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/list-supplier-by-like', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            // Recupera i nomi delle supplier person con i supplier caricati (per autocomplete & per la lista)
            listSupplierPersonByLike: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/list-supplier-person-by-like', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            // Crea un nuovo supplier
            createSupplier: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/create-supplier', {
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            // Crea una nuova supplier person
            createSupplierPerson: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/create-supplier-person', {
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            // Crea un nuovo supplier person
            updateSupplierPerson: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/update-supplier-person', {
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }, {
                cancellable: true
            }),


            // Recupera le informazioni di un supplier (le mostra nell'edit)
            /*getSupplierDetail: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-supplier-by-id/:tutorId', {
                TutorId: "@tutorId",
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            // Recupera i supplier con le persone (me li visualizza nella lista)
            listExistingSupplierName: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/list-suppliers-with-person', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),*/

        };
        return serv;
    }]);
}